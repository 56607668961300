h1 {
    font-weight: 700;
    font-size: 1.6rem;
    color: #6d6d6d;
}

.margin {
    margin: 5px;
}

#uploaded-image {
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
}

#output {
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
}

.overlay {
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    background-image:url(./dl.jpg) ;
    background-attachment:fixed;
    background-repeat:no-repeat;
    background-size:cover;
    -moz-background-size:cover;
    -webkit-background-size:cover;
    display: block;
    position: absolute;
    z-index: 999;   
}